@import url('https://rsms.me/inter/inter.css');
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

#blue { color: #7A9FBF; }
#yellow { color: #F2E205; }
#yellow2 { color: #F2B705; }
#orange { color: #F28705; }
#red { color: #BF2604; }


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background:#f0f0f0;
  color: #BF2604;
  font-family: 'Inter var', sans-serif;
  font-feature-settings: 'cv06' 1;
  opacity: 0.8;
}

h1 {
  font-size: 15vw;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 0.7em;
  margin: 0;
  padding: 0;
}

a {
  pointer-events: all;
  text-decoration: none;
}

svg {
  fill: black;
}


.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Elemente umbrechen, wenn sie nicht in eine Zeile passen */
}

.card {
  /*background-color: #d5d5d5;*/
  
  margin: 0 10px 20px; /* Hinzugefügter Abstand zwischen den Karten */
  padding: 20px;
  width: 45%;
  min-width: 50px;
  min-height: 100px;
  align-items: center;

  transition: 0.5s all ease 0s;
    -webkit-transition: 0.5s all ease 0s;
    -moz-transition: 0.5s all ease 0s;
    -ms-transition: 0.5s all ease 0s;
}



.card:hover {

  opacity: 1;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  transition: 0.5s all ease 0s;
  -webkit-transition: 0.5s all ease 0s;
  -moz-transition: 0.5s all ease 0s;
  -ms-transition: 0.5s all ease 0s;

}

.card-title {
  font-size: 4vw;
  margin-bottom: 40px;
  line-height: 100%;
  
}

.card-content {
  font-size: 3vw;
  line-height: 100%;
}

@media (max-height: 1000px){
  .card {
    width: 93%;
    margin: 0;  
  }
  .card-title {
    font-size: 4.5vw;
    margin-bottom: 10px;
  }
  .card-content {
    font-size: 4.5vw;
    margin-bottom: 0px;
  }

}

@media (max-width: 1200px){
  .card {
    width: 93%;
      
  }
  .card-title {
    font-size: 5vw;
    
  }
  .card-content {
    font-size: 4vw;
    
  }

}


#navbar {
  position: fixed; /* Make it stick/fixed */
  top: 40; /* Stay on top */
  width: 100%; /* Full width */
  transition: top 0.8s; /* Transition effect when sliding down (and up) */
  left: 50;
  height: 120px;
}

/* Style the navbar links */
#navbar a {
  float: left;
  display: block;
  text-decoration: none;
  position: 'absolute';
  top: 50;
  left: 90; 
  font-size: 13px;
}


#footer {
  padding: 20px 0; 
  position: absolute;
  font-size: 14px;
  bottom:-100px;
  display: block;
}


.footer-content {
  margin: 0 20px;
  
}

.footer-links a {
  margin: 0 10px;
  color: #555;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #000;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  max-width: 60%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: 80%;
}

.popup-section {
  margin-bottom: 20px;
  text-align: left;
  
}

.popup-section h2 {
  color: #333;
  font-size: 18px;
  margin-top: 0;
  
  
}

.scrollable-content {
  max-height: 100%;
  padding: 0 20px; /* Add padding on the left and right side */
  overflow: hidden; /* Hide overflowing content on the sides */
}

.scrollable-content p {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
}

.popup-content::before,
.popup-content::after {
  content: '';
  display: block;
  height: 100%;
  width: 20px; /* Adjust the width of the space */
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color of the space */
}

.popup-content::before {
  left: 0;
}

.popup-content::after {
  right: 0;
}

button {

  position: absolute;
  top: 0;
}


.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}



.languagebutton {
  color: #BF2604;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  position: fixed;
  top: 40px;
  right: 40px;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}

.reload-button:hover {
  background-color: #45a049;
}
